<template>
  <v-container class="account"> 
    <v-row justify="center" align="center">
      <v-col cols="3">
        <form @submit="onSubmit" v-if="this.data">
          <v-card class="mt-20">
            <v-card-title class="flex flex-col justify-center gap-y-5">
                <v-img max-height="80" max-width="80" src="/img/logo.png"/>
                <h1 class="account-text account-text--title">Administrare Cont</h1>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <p class="account-text account-text--text">
                    Selectati contul pe care doriti sa il accesati.
                </p>
                <v-select
                    outlined
                    label="Account"
                    v-model="account"
                    ref="account"
                    :rules="[() => !!account || 'Campul este obligatoriu']"
                    :items="data.accounts"
                    item-text="name"
                    item-value="hash_id"
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="loading" color="primary" type="submit" block>
                    Acceseaza Contul
                </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
    data: () => ({
        valid:false,
        loading:false,
        data:null,
        account:null
    }),
    mounted(){
        this.getAccounts();
    },
    methods: {
        ...mapActions(["setAccount"]),
        getAccounts(){
            this.$axios.get('/auth/me')
                .then(res=>{
                    this.data = res.data;
                })
        },
        onSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.valid = true;

            let fieldValid = this.$refs["account"].validate(true);
                // Unvalidate form if any fields are not valid
                if (!fieldValid) {
                    this.valid = false;
                }
            if (!this.valid) {
                this.loading = false;
                return;
            }

            this.setAccount(this.account);
            this.$router.push({ path: "/main/"+this.account });
        }
    }
}
</script>
